import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { StyleObject } from 'types/styles';
import Flicking from '@egjs/react-flicking';
import { Colors } from 'constants/Colors';
import { isMobile as isMobileDetected } from 'react-device-detect';
import { BookmarkButtonType, WishType } from 'types/interface';
import { HomeMeetupListSection } from 'templates/Main';
import { BookmarkButton } from 'components/Bookmark/BookmarkButton';
import { LogScreens } from 'utils/Analytics';
import { LinkWrapper } from './LinkWrapper';
import { PreOpenNotificationButton } from './PreOpenNotificationButton';
import { ComingSoonCardItem } from '../ComingSoon/ComingSoonCardItem';

interface ComingSoonCardSectionProps {
  data: HomeMeetupListSection;
}

export const ComingSoonCardSection = ({ data }: ComingSoonCardSectionProps) => {
  const section = useRef<Flicking>();
  const [isMobile, setIsMobile] = useState(isMobileDetected);

  const [openNotificationMap, onChangeOpenNotificationMap] = useState<
    Map<string, { clicked: boolean; count: number }>
  >(
    new Map(
      data.items.map(meetup => [
        meetup.meetup.id,
        {
          clicked:
            meetup.wishes.some(wish => wish.type === WishType.TYPE_OPEN_NOTIFICATION) ?? false,
          count: meetup.meetup?.openNotificationWishCount,
        },
      ]),
    ),
  );

  const handleResize = () => {
    setIsMobile(window && window.innerWidth < 640);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClickArrow = useCallback(
    (direction: number) => () => {
      if (section.current.animating) {
        return;
      }
      const { index, panelCount } = section.current;
      const interval = isMobile ? 1 : 2;
      if (direction === 1) {
        section.current.moveTo(
          panelCount - (interval + index) < 1 ? panelCount - 1 : index + interval,
        );
      } else {
        section.current.moveTo(index - interval < 0 ? 0 : index - interval);
      }
    },
    [isMobile],
  );

  const getItemStyle = useCallback(
    (index: number) => ({
      ...styles.item,
      ml: index === 0 ? 0 : ['10px', '20px'],
      pl: index === 0 ? ['20px', '0px'] : 0,
      width:
        index === 0 || (section.current && index === section.current.panelCount - 1)
          ? ['345px', 'calc(50% - 15px)']
          : ['330px', 'calc(50% - 15px)'],
    }),
    [],
  );

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.titleWrapper}>
        <Text sx={styles.title}>{data.title}</Text>
        {data.destinationUrl && (
          <LinkWrapper href={data.destinationUrl}>
            <Flex sx={styles.moreButton}>
              <Text sx={styles.moreButtonText}>더 보기</Text>
            </Flex>
          </LinkWrapper>
        )}
      </Flex>
      <Box sx={styles.itemContainer}>
        <Flicking
          align={{
            camera: '0px',
            panel: isMobile ? '-20px' : '0px',
          }}
          bounce="0%"
          ref={section}
          bound
          moveType={isMobile ? 'freeScroll' : 'snap'}
          duration={500}>
          {data.items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box sx={getItemStyle(index)} key={`coming-soon-card-item-${item.meetup.id}-${index}`}>
              <ComingSoonCardItem
                meetup={item.meetup}
                isMobile={isMobile}
                openNotificationCount={openNotificationMap.get(item.meetup.id).count}
              />
              <PreOpenNotificationButton
                meetupId={item.meetup.id}
                openNotification={openNotificationMap.get(item.meetup?.id)?.clicked ?? false}
                openNotificationCount={openNotificationMap.get(item.meetup?.id)?.count}
                onChangeOpenNotification={(state, newCount) =>
                  onChangeOpenNotificationMap(prevState =>
                    new Map(prevState).set(item.meetup?.id, {
                      clicked: state,
                      count: newCount,
                    }),
                  )
                }
                screenName={LogScreens.MAIN}
              />
              <BookmarkButton
                meetup={item.meetup}
                bookmarked={item.wishes.some(wish => wish.type === WishType.TYPE_BOOKMARK)}
                ButtonType={BookmarkButtonType.PREOPEN_SECTION}
                openNotificationCount={openNotificationMap.get(item.meetup?.id)?.count}
                onChangeOpenNotification={(state, newCount) =>
                  onChangeOpenNotificationMap(prevState =>
                    new Map(prevState).set(item.meetup?.id, {
                      clicked: state,
                      count: newCount,
                    }),
                  )
                }
              />
            </Box>
          ))}
          <Box sx={styles.emptyBox} />
        </Flicking>
      </Box>
      {!isMobile && (
        <Flex sx={styles.arrowWrapper}>
          <Flex sx={styles.arrowButton} onClick={onClickArrow(0)}>
            <Image sx={styles.arrowImage} src="/arrow_left_large.svg" />
          </Flex>
          <Flex sx={styles.arrowButton} onClick={onClickArrow(1)}>
            <Image sx={styles.arrowImage} src="/arrow_right_large.svg" />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const styles: StyleObject = {
  arrowButton: {
    '::selection': {
      background: 'transparent',
    },
    ':hover': {
      opacity: 0.8,
    },
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['13px', '20px'],
    cursor: 'pointer',
    height: ['26px', '40px'],
    justifyContent: 'center',
    ml: ['10px', '0px'],
    userSelect: 'none',
    width: ['26px', '40px'],
  },
  arrowImage: {
    height: ['16px', '20px'],
    width: ['16px', '20px'],
  },
  arrowWrapper: {
    alignItems: 'center',
    justifyContent: ['right', 'space-between'],
    left: ['none', '10px'],
    position: 'absolute',
    right: ['20px', '10px'],
    top: ['-2px', '140px'],
    zIndex: 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: ['100%', '1140px'],
    mx: 'auto',
    my: ['30px', '36px'],
    position: 'relative',
    px: ['0px', '20px'],
    width: '100%',
  },
  emptyBox: {
    height: '100%',
    width: ['20px', '0px'],
  },
  hostInfo: {
    bg: 'white',
    color: Colors.gray59,
    fontSize: '12px',
    fontWeight: '700',
    mb: '8px',
    p: '5px',
    width: 'fit-content',
  },
  image: {
    alignItems: 'flex-end',
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: ['235px', '250px'],
    overflow: 'hidden',
    p: '15px',
    position: 'relative',
    width: '100%',
  },
  item: {
    flexDirection: 'column',
    position: 'relative',
  },
  itemContainer: {
    maxWidth: '1000px',
    width: '100%',
  },
  itemSubText: {
    color: Colors.gray66,
    fontSize: ['12px', '15px'],
  },
  itemTitle: {
    color: Colors.gray11,
    fontSize: ['13px', '16px'],
    fontWeight: 'bold',
  },
  itemWrapper: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    height: ['300px', '380px'],
    overflow: 'hidden',
    width: '100%',
  },
  keyword: {
    color: Colors.gray11,
    fontSize: '12px',
    fontWeight: '700',
    mr: '4px',
  },
  keywordWrapper: {
    p: '10px',
  },
  meetupTitle: {
    color: Colors.gray11,
    fontSize: '14px',
    fontWeight: '700',
  },
  meetupTitleBg: {
    WebkitBoxDecorationBreak: 'clone',
    bg: 'white',
    boxDecorationBreak: 'clone',
    display: 'inline',
    lineHeight: 1.9,
    p: '5px',
  },
  moreButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['18px', '20px'],
    color: Colors.bluishGray82,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    height: ['36px', '40px'],
    px: ['14px', '13px'],
  },
  moreButtonText: {
    color: Colors.bluishGray82,
    fontSize: ['13px', '16px'],
    ml: ['2px', '7px'],
    mr: ['2px', '7px'],
  },
  textWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  title: {
    color: Colors.gray11,
    fontSize: ['20px', '24px'],
    fontWeight: 'bold',
    mr: 'auto',
  },
  titleWrapper: {
    alignItems: 'center',
    maxWidth: '1000px',
    mb: ['20px', '25px'],
    px: ['20px', '0px'],
    width: '100%',
  },
  wishIcon: {
    cursor: 'pointer',
    height: ['20px', '26px'],
    position: 'absolute',
    right: ['12px', '15px'],
    top: ['12px', '15px'],
    width: ['20px', '26px'],
    zIndex: 15,
  },
};
