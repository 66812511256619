/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Flex, Image, Text } from 'rebass';
import { WishType } from 'types/interface';
import { StyleObject } from 'types/styles';
import { useRouter } from 'next/router';
import { WishApi } from 'utils/Api/WishApi';
import { userState } from 'src/recoil/UserState';
import { useRecoilValue } from 'recoil';
import { Colors } from 'constants/Colors';
import { LogActions, logEventAction, LogScreens } from 'utils/Analytics';
import { defaultToast, noticeToast } from 'utils/Alert';
import { AfterLoginActionKeys } from 'constants/Constants';

interface PreOpenNotificationButtonProps {
  meetupId: string;
  openNotification: boolean;
  openNotificationCount: number;
  screenName: LogScreens;
  onChangeOpenNotification: (state: boolean, newCount: number) => void;
}

export const PreOpenNotificationButton = ({
  meetupId,
  openNotification,
  openNotificationCount,
  screenName,
  onChangeOpenNotification,
}: PreOpenNotificationButtonProps) => {
  const user = useRecoilValue(userState);
  const router = useRouter();

  const onClickPreOpenButton = useCallback(
    async (targetMeetupId: string) => {
      if (!user) {
        router.push(
          `/my?redirect=${encodeURIComponent(router.asPath)}&afterLoginAction=${
            AfterLoginActionKeys.OPEN_NOTIFICATION
          }/${targetMeetupId}`,
        );
      } else if (!openNotification) {
        onChangeOpenNotification(true, openNotificationCount + 1);
        logEventAction(screenName, LogActions.CLICK, 'preopen_notification', {
          meetup_id: targetMeetupId,
        });
        try {
          await WishApi.createWish({
            meetupId: targetMeetupId,
            type: WishType.TYPE_OPEN_NOTIFICATION,
          });
          defaultToast('해당 모임이 오픈되면 알림톡으로 알려드릴게요!');
        } catch (e) {
          if (e.response?.status === 409) {
            onChangeOpenNotification(true, openNotificationCount + 1);
          } else {
            onChangeOpenNotification(false, openNotificationCount);
            noticeToast('오픈 예정 알림 신청 실패! 넷플연가에 문의해주세요.');
          }
        }
      } else {
        onChangeOpenNotification(false, openNotificationCount - 1);
        logEventAction(screenName, LogActions.CLICK, 'cancel_preopen_notification', {
          meetup_id: targetMeetupId,
        });
        try {
          await WishApi.deleteWish({
            meetupId: String(targetMeetupId),
            type: WishType.TYPE_OPEN_NOTIFICATION,
          });
        } catch (e) {
          if (e.response?.status === 404) {
            onChangeOpenNotification(false, openNotificationCount - 1);
          } else {
            onChangeOpenNotification(true, openNotificationCount);
            noticeToast('오픈 예정 알림 취소 실패! 넷플연가에 문의해주세요.');
          }
        }
      }
    },
    [router, user, onChangeOpenNotification, openNotification, openNotificationCount, screenName],
  );

  return (
    <Flex
      sx={openNotification ? styles.checkButton : styles.uncheckButton}
      onClick={() => onClickPreOpenButton(meetupId)}>
      <Image sx={styles.preOpenIcon} src={openNotification ? '/bell_on.svg' : '/bell_off.svg'} />
      <Text sx={openNotification ? styles.checkText : styles.uncheckText}>
        {openNotification ? '알림 신청 완료' : '나도 오픈 알림 받기'}
      </Text>
    </Flex>
  );
};

const styles: StyleObject = {
  checkButton: {
    alignItems: 'center',
    bg: Colors.grayee,
    border: `1px solid ${Colors.grayee}`,
    borderRadius: '4px',
    cursor: 'pointer',
    height: ['42px', '48px'],
    justifyContent: 'center',
    width: '100%',
  },
  checkText: {
    color: '#8F8F8F',
    fontSize: ['13px', '16px'],
    fontWeight: 'bold',
  },
  preOpenIcon: {
    height: ['22px', '24px'],
    mr: '4px',
    width: ['22px', '24px'],
  },
  uncheckButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.gray11}`,
    borderRadius: '4px',
    cursor: 'pointer',
    height: ['42px', '48px'],
    justifyContent: 'center',
    width: '100%',
  },
  uncheckText: {
    color: Colors.gray11,
    fontSize: ['13px', '16px'],
    fontWeight: 'bold',
  },
};
