/* eslint-disable consistent-return */
import { Colors } from 'constants/Colors';
import React, { useMemo } from 'react';
import { Flex, Text, Image } from 'rebass';
import { Meetup, ContentType } from 'types/interface';
import { StyleObject } from 'types/styles';
import { buildHashtag, calculateDayDifference, isEndWithConsonant } from 'utils/TextUtils';
import { SALON_CATEGORY_TAG_ASSETS_MAP, TagType } from 'constants/Constants';
import {
  getResizedMeetupThumbnailImage,
  MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING,
} from 'utils/MeetupUtil';
import { LinkWrapper } from '../common/LinkWrapper';

interface ComingSoonCardItemProps {
  meetup: Meetup;
  isMobile: boolean;
  openNotificationCount: number;
}

export const ComingSoonCardItem = ({
  meetup,
  isMobile,
  openNotificationCount,
}: ComingSoonCardItemProps) => {
  const imageSrc = useMemo(() => getResizedMeetupThumbnailImage(meetup, [80, 110], isMobile), [
    isMobile,
    meetup,
  ]);
  const meetupCategoryTag = useMemo(() => meetup.tags[TagType.SALON_CATEGORY][0], [meetup.tags]);
  const tagAssets = useMemo(() => SALON_CATEGORY_TAG_ASSETS_MAP[meetupCategoryTag], [
    meetupCategoryTag,
  ]);

  const hostSubText = useMemo(() => {
    if (!meetup.additionalInformation?.memberLed && meetup.host) {
      return `${meetup.host.nickname}, ${meetup.host.title}`;
    }
    if (meetup.contents?.length > 0) {
      const findContent = meetup.contents?.find(content => content.title.length < 30);
      if (!findContent) {
        return '멤버들과 함께';
      }
      if ([ContentType.BOOK, ContentType.ARTICLE].includes(findContent?.contentType)) {
        return `『${findContent?.title}』${
          isEndWithConsonant(findContent.title) ? '을' : '를'
        } 사랑하는 멤버들과 함께`;
      }
      return `<${findContent?.title}>${
        isEndWithConsonant(findContent?.title) ? '을' : '를'
      } 사랑하는 멤버들과 함께`;
    }
    return '멤버들과 함께';
  }, [meetup]);

  const hashtagText = useMemo(() => {
    const textLengthLimit = isMobile ? 25 : 30;
    let resultText = '';
    if (!meetup.tags?.keyword) {
      return resultText;
    }
    meetup.tags.keyword.forEach(tag1 => {
      meetup.tags.keyword.forEach(tag2 => {
        if (tag1 !== tag2) {
          const candidate = `${buildHashtag(tag1.trim())} ${buildHashtag(tag2.trim())}`;
          if (candidate.length > resultText.length && candidate.length <= textLengthLimit) {
            resultText = candidate;
          }
        }
      });
    });
    return resultText;
  }, [isMobile, meetup.tags.keyword]);

  const openNotiInfo = useMemo(() => {
    if (
      openNotificationCount > 0 &&
      openNotificationCount / MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING < 0.65 // 13명 기준
    ) {
      return {
        subIconSrc: '/trending_up.svg',
        text: `지금 ${openNotificationCount}명이 기다려요.`,
        textColor: Colors.nfygOrange,
      };
    }
    if (
      openNotificationCount / MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING >= 0.65 &&
      openNotificationCount < MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING
    ) {
      return {
        tagBgColor: 'white',
        tagText: '오픈 유력',
        tagTextColor: Colors.nfygOrange,
        text: `지금 ${openNotificationCount}명이 기다려요.`,
        textColor: Colors.nfygOrange,
      };
    }
    if (openNotificationCount >= MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING) {
      return {
        tagBgColor: Colors.nfygOrange,
        tagText: '오픈 확정',
        tagTextColor: 'white',
        text: `지금 ${openNotificationCount}명이 기다려요.`,
        textColor: Colors.nfygOrange,
      };
    }
    return {
      subIconSrc: '/gray_flag.svg',
      text: '지금 첫번째 대기자가 되어주세요.',
      textColor: Colors.nfygGray,
    };
  }, [openNotificationCount]);

  return (
    <LinkWrapper href={`/meetups/${meetup.id}`} sx={styles.link}>
      <Flex sx={styles.cardContainer}>
        <Flex sx={styles.infoContainer} flexDirection="row">
          <Image sx={styles.applyThumbnail} src={imageSrc} />
          <Flex sx={styles.textWrapper}>
            <Flex sx={styles.subInfo}>
              {meetup.additionalInformation?.reopeningHost && (
                <>
                  <Image src="/encore_badge.svg" sx={styles.encoreIcon} />
                  <Text sx={styles.encoreText} mr="3px">
                    앵콜 모임장 ·
                  </Text>
                </>
              )}
              <Text
                sx={{
                  ...styles.hostInfo,
                  maxWidth: meetup.additionalInformation?.reopeningHost
                    ? ['calc(100% - 75px)', '190px']
                    : 'auto',
                }}>
                {hostSubText}
              </Text>
            </Flex>
            <Text sx={styles.meetupTitle}>
              {meetup.title?.trim()}
              {calculateDayDifference(new Date(), new Date(meetup.releasedAt)) <= 10 &&
                openNotificationCount / MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING < 0.65 && (
                  <Image src="/new_icon.svg" sx={styles.newIcon} />
                )}
            </Text>
            <Flex alignItems={['center', 'center']}>
              {openNotiInfo.tagText && (
                <Flex
                  sx={{
                    ...styles.bagde,
                    bg: openNotiInfo.tagBgColor,
                    color: openNotiInfo.tagTextColor,
                  }}>
                  {openNotiInfo.tagText}
                </Flex>
              )}
              {openNotiInfo.subIconSrc && (
                <Image sx={styles.stateIcon} src={openNotiInfo.subIconSrc} />
              )}
              <Text sx={{ ...styles.stateText, color: openNotiInfo.textColor }}>
                {openNotiInfo.text}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex sx={{ ...styles.keywordWrapper, bg: tagAssets.color.regular }}>
          <Text sx={styles.keyword}>{hashtagText}</Text>
        </Flex>
      </Flex>
    </LinkWrapper>
  );
};

const styles: StyleObject = {
  applyThumbnail: {
    border: '1px solid #fff',
    borderRadius: '60px',
    height: ['80px', '110px'],
    minHeight: ['80px', '110px'],
    minWidth: ['80px', '110px'],
    mr: ['12px', '20px'],
    objectFit: 'cover',
    width: ['80px', '110px'],
    zIndex: 2,
  },
  bagde: {
    alignItems: 'center',
    border: `1px solid ${Colors.nfygOrange}`,
    borderRadius: '4px',
    fontSize: '10px',
    fontWeight: 'bold',
    height: '20px',
    justifyContent: 'center',
    lineHeight: '20px',
    mr: '5px',
    px: '5px',
  },
  cardContainer: {
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: '4px',
    flexDirection: 'column',
    mb: '6px',
    width: '100%',
  },
  encoreIcon: {
    height: ['14px', '16px'],
    mr: '4px',
    width: ['14px', '16px'],
  },
  encoreText: {
    color: Colors.gray66,
    fontSize: ['9px', '12px'],
    width: ['fit-content', '65px'],
  },
  hostInfo: {
    color: Colors.gray66,
    fontSize: ['9px', '12px'],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  infoContainer: {
    mb: ['0px', '-30px'],
    p: ['16px 12px', '20px 20px 25px 20px'],
  },
  keyword: {
    color: 'white',
    fontSize: ['9px', '12px'],
  },
  keywordWrapper: {
    alignItems: 'center',
    height: ['22px', '26px'],
    justifyContent: 'center',
  },
  meetupTitle: {
    WebkitLineClamp: '2',
    color: Colors.gray11,
    display: '-webkit-box',
    fontSize: ['12px', '14px'],
    fontWeight: '700',
    height: 'auto',
    lineHeight: ['18px', '20px'],
    maxHeight: ['36px', '40px'],
    mb: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  newIcon: {
    height: '14px',
    mb: ['-2.5px', '-2px'],
    ml: '4px',
    width: '14px',
  },
  stateIcon: {
    height: '17px',
    mr: '5px',
    width: '17px',
  },
  stateText: {
    fontSize: ['11px', '12px'],
    fontWeight: 'bold',
  },
  subInfo: {
    alignItems: 'flex-end',
    mb: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textWrapper: {
    flexDirection: 'column',
    mr: ['20px', '30px'],
  },
};
