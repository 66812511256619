import { Colors } from 'constants/Colors';
import React from 'react';
import { Flex } from 'rebass';
import { StyleObject } from 'types/styles';
import Loading from 'react-spinners/PulseLoader';

interface ListLoadingProps {
  isInitialLoading: boolean;
  initialLoadingHeight: string;
  loadingColor?: string;
}

export const ListLoading = ({
  isInitialLoading,
  initialLoadingHeight,
  loadingColor = Colors.gray66,
}: ListLoadingProps) => (
  <Flex sx={{ 
    ...styles.loadingWrraper,
    minHeight: isInitialLoading ? initialLoadingHeight : '100px', // 최초 로딩시 각 페이지별 설정한 높이로 로딩
    }}>
    <Loading size={10} color={loadingColor} />
  </Flex>
);

const styles: StyleObject = {
  loadingWrraper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};
